<template>
  <div class="swiper-mobile">
    <SliderSwiperArrows v-model="swiper" class="arrows" group-centered />
    <SliderSwiperWrapper v-model="swiper" slides-per-view="1" class="swiper" centered-slides>
      <slot />
    </SliderSwiperWrapper>
  </div>
</template>

<script lang="ts" setup>
import { SwiperBinding } from '@/lib/types/models/swiper';

const { backgroundColor } = defineProps<{
  backgroundColor?: string;
  arrowsColor?: string;
}>();

const swiper = ref<SwiperBinding>();
</script>

<style lang="scss" scoped>
.swiper-mobile {
  position: relative;

  flex-direction: column;

  width: 100vw;
  padding: 27.5px 0;

  background-color: v-bind(backgroundColor);

  :deep(.swiper-wrapper) {
    align-items: center;
  }

  .swiper {
    :deep(.swiper-slide) {
      justify-content: center;
    }
  }

  .arrows {
    position: absolute;
    z-index: 10;
    top: calc(50% - 24px);

    width: 100%;
    padding: 0 16px;
  }

  :deep(.arrows) {
    justify-content: space-between;

    .left,
    .right {
      width: 24px;
      height: 24px;
      background-color: v-bind(backgroundColor);
    }
  }
}
</style>
